import {
  Section,
  Grid,
  Column,
  Text,
  Layout,
  Alignment,
  Slash,
  Divider,
  Icon,
  Box,
  BcorpIcon,
  FacebookIcon,
  InstagramIcon,
  ItunesIcon,
  SpotifyIcon,
  XIcon,
  SoundcloudIcon,
  YoutubeIcon,
  RAIcon,
  variants,
} from '@resident-advisor/design-system'
import styled, { css, ThemeProvider } from 'styled-components'
import { useIntl } from 'react-intl'
import Link from 'components/generic/link'
import messages from 'messages/dict'
import { dark } from 'themes'
import trackingIds from 'tracking/tracking-ids'
import { useServerTime } from 'context/ServerTimeContext'
import eventMessages from 'messages/events'
import LinkButton from 'components/generic/link-button'
import isInView from 'lib/isInView'
import LocationSelector from './location-selector'
import LanguageSelector from './language-selector'

const FooterId = 'RA-Page-Footer'

const FooterWrapper = styled.div`
  flex-shrink: 0;
`

const Footer = () => (
  <ThemeProvider theme={dark}>
    <FooterWrapper>
      <Section data-tracking-id={trackingIds.footer} id={FooterId}>
        <Layout>
          <RALogo />
          <Nav />
          <Alignment
            flexDirection={{ s: 'column', m: 'row' }}
            justifyContent={{ s: 'flex-start', m: 'space-between' }}
          >
            <AreYouAPromoter />
            <Alignment flexDirection="column" justifyContent="center">
              <StyledIcon
                color="primary"
                Component={BcorpIcon}
                width={50}
                height={80}
                p={2}
                mt={{ s: 4, m: 0 }}
              />
            </Alignment>
          </Alignment>
          <LanguageAndLegal />
          <CopyrightAndSocial />
        </Layout>
      </Section>
    </FooterWrapper>
  </ThemeProvider>
)

const AreYouAPromoter = () => {
  const intl = useIntl()

  return (
    <Alignment
      justifyContent="center"
      alignItems="start"
      flexDirection="column"
      mt={3}
    >
      <Text mb={3} variant={variants.text.heading.l} color="secondary">
        {intl.formatMessage(eventMessages.areYouAPromoter)}
      </Text>
      <LinkButton href="/pro/event/create" variant={variants.button.secondary}>
        {intl.formatMessage(eventMessages.submitEvent)}
      </LinkButton>
    </Alignment>
  )
}

const RALogo = () => (
  <Icon
    size={56}
    Component={RAIcon}
    pb={{ s: 3, m: 4 }}
    pt={3}
    color="primary"
  />
)

const AlignmentWithPaddedChildren = styled(Alignment)`
  & > * {
    ${({ theme, pb }) =>
      css`
        padding-bottom: ${theme.space[pb]}px;
      `};
  }
`

const Nav = () => {
  const intl = useIntl()

  return (
    <Alignment
      flexDirection={{ s: 'column', m: 'row' }}
      justifyContent="flex-end"
    >
      <Box mr="auto">
        <Text variant={variants.text.label} color="secondary">
          {intl.formatMessage(messages.discover)}
        </Text>
        <AlignmentWithPaddedChildren
          flexWrap="wrap"
          pt={3}
          pb={2}
          width={{ s: '100%' }}
        >
          <Box>
            <Link
              href="/about"
              variant={variants.text.heading.l}
              color="primary"
              pr={2}
            >
              {intl.formatMessage(messages.about)}
            </Link>
            <Slash variant={variants.text.heading.l} color="tertiary" />
          </Box>
          <Box>
            <Link
              href="/tickets"
              variant={variants.text.heading.l}
              color="primary"
              pr={2}
            >
              {intl.formatMessage(messages.tickets)}
            </Link>
            <Slash variant={variants.text.heading.l} color="tertiary" />
          </Box>
          <Box>
            <Link
              href="/tickets/resale"
              variant={variants.text.heading.l}
              color="primary"
              pr={2}
            >
              {intl.formatMessage(messages.resale)}
            </Link>
            <Slash variant={variants.text.heading.l} color="tertiary" />
          </Box>
          <Box>
            <Link
              href="/about/advertising"
              variant={variants.text.heading.l}
              color="primary"
              pr={2}
            >
              {intl.formatMessage(messages.advertise)}
            </Link>
            <Slash variant={variants.text.heading.l} color="tertiary" />
          </Box>
          <Box>
            <Link
              href="https://www.doorsopen.co/company/12/resident-advisor"
              target="_blank"
              rel="noopener"
              variant={variants.text.heading.l}
              color="primary"
              pr={2}
            >
              {intl.formatMessage(messages.jobs)}
            </Link>
          </Box>
        </AlignmentWithPaddedChildren>
      </Box>
      <Box pt={{ s: 4, m: 0 }}>
        <Text variant={variants.text.label} color="secondary" pb={2}>
          {intl.formatMessage(messages.getRAApps)}
        </Text>

        <AlignmentWithPaddedChildren
          flexWrap="wrap"
          pt={3}
          pb={2}
          width={{ s: '100%' }}
        >
          <Box>
            <Link
              href="/ra-guide"
              variant={variants.text.heading.l}
              color="primary"
              mb={2}
            >
              RA&nbsp;Guide
            </Link>
            <Slash variant={variants.text.heading.l} color="tertiary" pl={2} />
          </Box>
          <Box>
            <Link
              href="/tickets/scanner"
              variant={variants.text.heading.l}
              color="primary"
            >
              RA&nbsp;Scanner
            </Link>
          </Box>
        </AlignmentWithPaddedChildren>
      </Box>
    </Alignment>
  )
}

const LanguageAndLegal = () => {
  const intl = useIntl()

  return (
    <>
      <Box pt={6} mb={{ s: 3, m: 2 }}>
        <Grid sCols={1} mCols={2} sWrap mWrap gridGap={{ s: 2, m: 0 }}>
          <Column>
            <Alignment>
              <LocationSelector />
              <LanguageSelector />
            </Alignment>
          </Column>
          <Column>
            <Alignment justifyContent={{ m: 'flex-end' }} flexWrap="wrap">
              <Link
                href="/privacy"
                color="primary"
                variant={variants.text.small}
              >
                {intl.formatMessage(messages.privacy)}
              </Link>
              <Text variant={variants.text.small} px={1}>
                ·
              </Text>
              <Link href="/terms" color="primary" variant={variants.text.small}>
                {intl.formatMessage(messages.terms)}
              </Link>
              <Text variant={variants.text.small} px={1}>
                ·
              </Text>
              <Link
                href="/cookiepolicy"
                color="primary"
                variant={variants.text.small}
              >
                {intl.formatMessage(messages.cookies)}
              </Link>
              <Text variant={variants.text.small} px={1}>
                ·
              </Text>
              <Link
                href="/sitemap"
                color="primary"
                variant={variants.text.small}
              >
                {intl.formatMessage(messages.sitemap)}
              </Link>
            </Alignment>
          </Column>
        </Grid>
      </Box>

      <Divider />
    </>
  )
}

const CopyrightAndSocial = () => {
  const intl = useIntl()
  const serverTime = useServerTime()

  return (
    <Box pt={{ s: 3, m: 2 }} pb={{ s: 4, m: 3 }}>
      <Alignment
        flexDirection={{ s: 'column', m: 'row' }}
        justifyContent="flex-end"
      >
        <Box mr="auto" pb={2}>
          <Text variant={variants.text.small} color="secondary" pb={2}>
            © {new Date(serverTime).getFullYear()} Resident Advisor Ltd.{' '}
            {intl.formatMessage(messages.allRightsReserved)}.
          </Text>
        </Box>
        <AlignmentWithPaddedChildren
          pb={2}
          justifyContent={{ s: 'flex-start', l: 'flex-end' }}
          flexWrap="wrap"
          width={{ s: '80%', m: 'auto' }}
        >
          <Link
            noStyle
            href="https://www.youtube.com/residentadvisor"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={YoutubeIcon}
              pr={{ s: 4, m: 3 }}
            />
          </Link>
          <Link
            noStyle
            href="https://soundcloud.com/resident-advisor"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={SoundcloudIcon}
              px={{ m: 3 }}
              pr={{ s: 4 }}
            />
          </Link>
          <Link
            noStyle
            href="https://open.spotify.com/user/residentadvisor"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={SpotifyIcon}
              px={{ m: 3 }}
              pr={{ s: 4 }}
            />
          </Link>
          <Link
            noStyle
            href="https://itunes.apple.com/gb/curator/resident-advisor/id996091687"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={ItunesIcon}
              px={{ m: 3 }}
              pr={{ s: 4 }}
            />
          </Link>
          <Link
            noStyle
            href="https://twitter.com/residentadvisor"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={XIcon}
              px={{ m: 3 }}
              pr={{ s: 4 }}
            />
          </Link>
          <Link
            noStyle
            href="https://www.facebook.com/residentadvisor"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={FacebookIcon}
              px={{ m: 3 }}
              pr={{ s: 4 }}
            />
          </Link>
          <Link
            noStyle
            href="https://www.instagram.com/resident_advisor/"
            target="_blank"
            rel="noopener"
          >
            <StyledIcon
              color="primary"
              Component={InstagramIcon}
              pl={{ s: 0, m: 3 }}
            />
          </Link>
        </AlignmentWithPaddedChildren>
      </Alignment>
    </Box>
  )
}

const StyledIcon = styled(Icon)`
  &:hover {
    ${({ theme }) =>
      css`
        color: ${theme.colors.accent};
      `};
  }
`

const isFooterInView = () => isInView(FooterId)
Footer.FooterId = FooterId

export { isFooterInView }
export default Footer
